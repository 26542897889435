@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Mitr:wght@200;300;400;500;600;700&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Mitr", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.table {
  border-spacing: 0 15px;
}

i {
  font-size: 1rem !important;
}

.table tr {
  border-radius: 20px;
}
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #48bb78;
}
.indicator {
  cursor: pointer;
  padding: 6px;
  margin-left: 4px;
  text-align: center;
  border: 1px #3c733f;
  background: #c4c4c4;
}

.indicator.active {
  color: #676767;
  background: #222655;
}
/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #0a7db1;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
/* SettingNavbar */
.topnav {
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 4px 4px #e5e5e5;
}

.topnav a {
  float: left;
  display: block;
  color: #676767;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  border-bottom: 4px solid transparent;
}

.topnav a:hover {
  border-bottom: 4px solid #0a7db1;
}

.topnav a.active {
  border-bottom: 4px solid #0a7db1;
}
.cardwallet {
  background: #ffffff;
  box-shadow: 0px 4px 4px #e5e5e5;
}

.shadowModal {
  box-shadow: -4px -4px 20px #c4c4c4, 4px 4px 20px #c4c4c4;
}
button[type="checkbox"] {
  /* change "blue" browser chrome to yellow */
  filter: hue-rotate(45deg) brightness(1);
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
